import React from 'react';
import './App.css';
import {
  Typography,
  Button,
  TextField,
  AppBar,
  Toolbar,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useFirestore } from './useFirestore';
import heroImage from './assets/hackerbloghero.png';


function App() {
  const [email, setEmail] = useState('');
  const [isSubscribed, setSubscribed] = useState(false);
  const { addDocument, response } = useFirestore('emails');

  useEffect(() => {
    if (response.success) {
      setSubscribed(response.success)
    }
}, [response.success])

  // console.log('outside', response)
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await addDocument({email});
    // console.log('inside', response)
    // setSubscriptionResponse(response);
  }

  return (
    <div className='maincontainer'>
      <AppBar position="static" className='Navbar'>
        <Toolbar>
          {/* Add your logo here */}
          <Typography variant="h6">Hacker Blog Reviews</Typography>
        </Toolbar>
      </AppBar>

      <section className="main-section">
      <div className="left-section">
          <Typography variant="h2" gutterBottom>
            Top Tech Blog Reviews
          </Typography>
          <Typography variant="body1" paragraph>
            Monthly review of posts from tech blogs of top companies and developers.
            Stay up to date on the latest developments in the tech and data science communities.
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <input
              id="outlined-basic"
              label="Email Address"
              variant="outlined"
              type="email"
              placeholder="Email Address"
              style={{
                padding: '10px',
                margin: '5px 0',
                borderRadius: '4px',
                border: '1px solid #ccc',
                boxSizing: 'border-box',
                width: '100%',
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: '10px', backgroundColor: isSubscribed ? '#4CAF50' : '#2196f3'}}
              disabled={response.isPending}
            >
              {isSubscribed ? 'Subscribed ✅' : 'Subscribe'}
            </Button>
          </form>
        </div>

        <div className="right-section">
          <img
            src={heroImage}
            alt="Hero"
            className="hero-image"
          />
        </div>
      </section>

      <footer className="footer">
        <Typography variant="body2" color="textSecondary" align="center">
          © {new Date().getFullYear()} Hacker Blog Reviews. All rights reserved.
        </Typography>
      </footer>
    </div>
  );
}

export default App;
